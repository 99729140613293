<template>
  <v-container fluid>
  <v-row class="footer-col pa-4 hidden-md-and-up">

    <v-col cols="6">
    <v-img
      class="footer-img"
      src="@/assets/asr-logo-2.png"
      min-width="100"
      max-width="150"
      @click="$router.push({ name: 'portal'})"
      contain
    ></v-img>
    </v-col>

    <v-col cols="7">
    <div class="contact mx-4 mt-2">
      <p class="my-0" v-for="(c,i) in contact" :key="i">{{ c }}</p>
    </div>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  <v-row class="footer-col pa-4 hidden-sm-and-down">

    <v-col cols="1">
    <v-img
      class="footer-img pa-1"
      src="@/assets/asr-logo-2.png"
      min-width="100"
      max-width="150"
      @click="$router.push({ name: 'portal'})"
      contain
    ></v-img>
    </v-col>
    <v-spacer></v-spacer>

    <v-col cols="10">
    <div class="contact mx-12">
      <p class="my-0" v-for="(c,i) in contact" :key="i">{{ c }}</p>
    </div>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
  </v-container>

  
</template>

<style scoped>
p {
  font-size: 14px;
  font-weight: 600;

}
.contact {
  float: left;
}
.footer-col {
  position: absolute;
  background: #f7941e;
  bottom: 0px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: -50px;
}
.footer-img {
  float: left;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "Footer",
  data() {
    return {
      contact: [
        "Raimo Pyyny",
        "Klusterijohtaja",
        "raimo.pyyny@lapinamk.fi",
        "+358 40 555 8065"
      ]
    };
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    }
  }
};
</script>
